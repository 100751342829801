import TTLCache from '@isaacs/ttlcache';
import type { ContentfulModel } from '@troon/contentful';

const defaultTTL = 600_000;
export const ttlCache = new TTLCache({ ttl: defaultTTL, checkAgeOnGet: true, max: 500 });

export const modelTTLOverride: Record<ContentfulModel, number> = {
	articles: defaultTTL,
	dei: defaultTTL,
	'dei-themes': defaultTTL,
	events: defaultTTL,
	benefits: defaultTTL,
	'person-list': defaultTTL,
	'press-release': defaultTTL,
	'prive-data': defaultTTL,
	'plain-document': defaultTTL,
	'site-banner': defaultTTL,
	cards: defaultTTL,
	faqs: defaultTTL,
};
